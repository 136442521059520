import React from "react";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Section } from "@atoms/Section";
import ICONS from "@config/icons";
import Text from "@ds/components/Text";
import type { IExplore } from "../../../interface";
import useStyles from "../styles";
import useResponsive from "@helpers/useResponsive";

const ProductSection: React.VFC = () => {
  const classes = useStyles({ isVisible: true });
  const { t } = useTranslation("common");
  const isTablet = useResponsive("lg");

  const productExplore = [
    {
      title: t("product.1.title"),
      desc: t("product.1.sub"),
      icon: ICONS.ICON_BUSINESS_RED,
      url: "/business-process-outsourcing",
      alt: "business-process-outsourcing",
    },
    {
      title: t("product.2.title"),
      desc: t("product.2.sub"),
      icon: ICONS.ICON_CRM,
      url: "/customer-relationship-management",
      alt: "customer-relationship-management",
    },
    {
      title: t("product.3.title"),
      desc: t("product.3.sub"),
      icon: ICONS.ICON_SECURITY,
      url: "/managed-network-security",
      alt: "managed-network-security",
    },
    {
      title: t("product.4.title"),
      desc: t("product.4.sub"),
      icon: ICONS.ICON_CONNECTIVITY,
      url: "/digital-connectivity-services",
      alt: "digital-connectivity-services",
    },
    {
      title: t("product.5.title"),
      desc: t("product.5.sub"),
      icon: ICONS.ICON_APP,
      url: "/digital-application-services",
      alt: "digital-application-services",
    },
    {
      title: t("product.6.title"),
      desc: t("product.6.sub"),
      icon: ICONS.ICON_FINTECH,
      url: "/fintech-solutions",
      alt: "fintech-solutions",
    },
    {
      title: t("product.7.title"),
      desc: t("product.7.sub"),
      icon: ICONS.ICON_CLOUD,
      url: "/data-cloud-services",
      alt: "data-cloud-services",
    },
    {
      title: t("product.8.title"),
      desc: t("product.8.sub"),
      icon: ICONS.ICON_BIG_DATA,
      url: "/big-data",
      alt: "big-data",
    },
    {
      title: t("product.9.title"),
      desc: t("product.9.sub"),
      icon: ICONS.ICON_IOT,
      url: "/internet-of-things",
      alt: "internet-of-things",
    },
    {
      title: t("product.10.title"),
      desc: t("product.10.sub"),
      icon: ICONS.ICON_SATELITE,
      url: "/satellite-communication",
      alt: "satellite-communication",
    },
    {
      title: t("product.11.title"),
      desc: t("product.11.sub"),
      icon: ICONS.ICON_ADVERTISING,
      url: "/digital-advertising",
      alt: "digital-advertising",
    },
  ];

  return (
    <>
      <Section
        title={t("explore")}
        id="product-customer"
        maxWidth="full-width"
        sx={{ ".slick-list": { paddingLeft: "0 !important" } }}
        px={isTablet ? "80px" : "auto"}
      >
        <Container maxWidth="lg" disableGutters>
          <Box display="flex" flexWrap="wrap" columnGap="75px">
            {productExplore.map((item: IExplore, idx: number) => (
              <NextLink href={item.url} passHref key={idx}>
                <a className={classes.boxExplore}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap={2}
                    padding="10px"
                    // width="350px"
                    width={isTablet ? "280px" : "350px"}
                  >
                    <img src={item.icon} alt={item.alt} width="40px" height="40px" />
                    <Box display="flex" flexDirection="column">
                      <Text component="p" weight="700">
                        {item.title}
                      </Text>
                      <Text color="inactive-secondary" variant="body2">
                        {item.desc}
                      </Text>
                    </Box>
                  </Box>
                </a>
              </NextLink>
            ))}
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default ProductSection;
