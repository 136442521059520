import NextLink from "next/link";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Carousel } from "@atoms/Carousel";
import { Section } from "@atoms/Section";
import Button from "@ds/components/Button";
import { ProductCard } from "@atoms/ProductCard";
import type { ArticleCustomer } from "../../../interface";

type ArticleSectionProps = {
  articleCustomer?: Array<ArticleCustomer>;
  isDesktop: boolean;
};

const ArticleSection: React.VFC<ArticleSectionProps> = ({ articleCustomer, isDesktop }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Section
        variant="gray"
        title={t("insight.title")}
        maxWidth="full-width"
        sx={{ ".slick-list": { paddingLeft: "0 !important" } }}
        px="110px"
      >
        <Container maxWidth="lg" disableGutters>
          <Carousel settings={{ slidesToShow: isDesktop ? 4 : 1 }} centerMode={true}>
            {articleCustomer?.map((item, index) => {
              return (
                <Box px={1} key={index}>
                  <ProductCard
                    title={item.title}
                    subTitle={dayjs(item.createdAt).format("DD MMM YYYY")}
                    label={item.category}
                    height={300}
                    image={item.imageUrl?.mediaPath}
                    imgQuality={75}
                    overflow="hidden"
                    linkTo={`/insight/${item.slug}`}
                  />
                </Box>
              );
            })}
          </Carousel>
          <Box textAlign="center" marginTop="40px">
            <NextLink href={"/insight"}>
              <a>
                <Button size="large" variant="secondary">
                  {t("insight.button")}
                </Button>
              </a>
            </NextLink>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default ArticleSection;
