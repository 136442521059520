import { SyntheticEvent, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Zoom from "@mui/material/Zoom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Container from "@mui/material/Container";
import { TabPanel } from "@atoms/TabPanel";
import { ProductCard } from "@atoms/ProductCard";
import { Section } from "@atoms/Section";
import Text from "@ds/components/Text";
import { useTranslation } from "next-i18next";
import ICONS from "@config/icons";
import { IMG_KEY_1, IMG_KEY_2, IMG_KEY_3, IMG_KEY_4 } from "@config/images";
import DynamicImage from "@ds/components/DynamicImage";

const SuperioritySection: React.VFC = () => {
  const [tabActive, setTabActive] = useState(0);
  const { t } = useTranslation("common");
  const handleChangeTab = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setTabActive(newValue);
  };

  const mytensKeyFeatureImage: Array<{ image: string; alt: string }> = [
    {
      image: IMG_KEY_1,
      alt: "Pemantauan Mudah",
    },
    {
      image: IMG_KEY_2,
      alt: "Informasi Proyek",
    },
    {
      image: IMG_KEY_3,
      alt: "Lacak Kinerja",
    },
    {
      image: IMG_KEY_4,
      alt: "Rincian Layanan",
    },
  ];

  const mytensKeyFeature = [
    {
      title: t("point_benefit.1.title"),
      description: t("point_benefit.1.desc"),
      icon: ICONS.ICON_OVERVIEW,
    },
    {
      title: t("point_benefit.2.title"),
      description: t("point_benefit.2.desc"),
      icon: ICONS.ICON_PROJECT_INFO,
    },
    {
      title: t("point_benefit.3.title"),
      description: t("point_benefit.3.desc"),
      icon: ICONS.ICON_PERFORMANCE,
    },
    {
      title: t("point_benefit.4.title"),
      description: t("point_benefit.4.desc"),
      icon: ICONS.ICON_PROJECT_INFO,
    },
  ];

  return (
    <>
      <Section variant="gray">
        <Container maxWidth="lg" disableGutters>
          <Box maxWidth="600px" mb={2} mx="auto" textAlign="center">
            <Text color="primary-main" variant="h2" weight="700">
              {t("benefit.subtitle")}
            </Text>
            <Box mb={2} mt={2}>
              <Text variant="h1" weight="700" sx={{ fontSize: 32, lineHeight: "48px" }}>
                {t("benefit.title")}
              </Text>
            </Box>
            <Box mb={6}>
              <Text color="inactive-secondary">{t("benefit.desc")}</Text>
            </Box>
          </Box>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={7}>
              {mytensKeyFeatureImage?.map((item, key) => (
                <TabPanel value={tabActive} index={key}>
                  <Zoom in={!!item}>
                    <Box>
                      <DynamicImage
                        src={item.image + "?tr=w-700"}
                        alt={`Keunggulan ${item.alt}`}
                        blurDataURL={item.image + "?tr=w-700"}
                        placeholder="blur"
                      />
                    </Box>
                  </Zoom>
                </TabPanel>
              ))}
            </Grid>
            <Grid item xs={4}>
              <Tabs
                orientation="vertical"
                value={tabActive}
                onChange={handleChangeTab}
                sx={{
                  ".MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                {mytensKeyFeature?.map((item, index) => (
                  <Tab
                    key={index}
                    disableRipple
                    sx={{
                      maxWidth: "100%",
                      paddingX: index === tabActive ? 0 : "30px",
                      transition: "padding 0.1s ease",
                    }}
                    label={
                      <ProductCard
                        key={index}
                        sideImage={item.icon}
                        imageHeight={0}
                        title={
                          <Text
                            color={index === tabActive ? "primary-main" : "general-main"}
                            variant="h2"
                            weight="700"
                          >
                            {item.title}
                          </Text>
                        }
                        subTitle={
                          index === tabActive && (
                            <Text color="general-main" weight="400">
                              {item.description}
                            </Text>
                          )
                        }
                        padding="24px"
                        paddingTop="24px"
                        textAlign="left"
                        width="100%"
                        boxShadow={
                          index === tabActive
                            ? "2px 6px 6px rgba(46, 67, 77, 0.1)"
                            : "0px 0px 0px rgba(46, 67, 77, 0.1)"
                        }
                        sx={{ transition: "box-shadow 0.1s ease" }}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default SuperioritySection;
