import { i18n } from "next-i18next";

const getAdminWA = (serviceName?: any) => {
  const lang = i18n?.language;
  const nameService = serviceName ? serviceName : "MyTEnS";

  const text =
    `${
      lang === "en"
        ? `Hello, I'm interested in ${nameService}, here's my personal data:`
        : `Halo, saya tertarik dengan produk ${nameService}, berikut data diri saya:`
    }` +
    "\r\n" +
    `${lang === "en" ? "Name:" : "Nama:"}` +
    "\r\n" +
    `${lang === "en" ? "Company:" : "Perusahaan:"}` +
    "\r\n\r\n" +
    `${
      lang === "en"
        ? "Please provide further information, thank you."
        : "Mohon info lanjutannya, terimakasih."
    }`;
  return `https://api.whatsapp.com/send?phone=6281119678999&text=${encodeURIComponent(text)}`;
};

export default getAdminWA;
