/* eslint-disable @typescript-eslint/no-explicit-any */
import color from "@definitions/mui/color";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  base: {
    backgroundColor: "#FFFFFF !important",
    paddingTop: 70,
    maxWidth: "100%",
    minHeight: "100vh",
    fontSize: 16,
    position: "relative",
    color: "#2E434D",
  },
  boxExplore: {
    "&:hover": {
      "& p": {
        color: color.primary.main,
      },
      "& span": {
        color: color.general.main,
      },
      display: "block",
      borderRadius: "12px",
      boxShadow: "0 2px 8px rgb(0 0 0 / 0.1)",
      transition: "all 200ms ease-in",
    },
    marginBottom: 24,
  },
  card: {
    border: `1px solid #DEE3ED`,
    borderRadius: 12,
  },
  subtitle: {
    marginBottom: 16,
  },
  title: {
    fontSize: 40,
    lineHeight: "54px",
    marginBottom: 16,
  },
  titleReguler: {
    fontSize: 32,
    marginBottom: 24,
    lineHeight: "48px",
  },
  text: {
    marginBottom: 28,
  },
  bgBlur: {
    position: "relative",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      filter: "blur(8px)",
      left: 0,
      top: 0,
      opacity: 0.2,
      zIndex: -1,
    },
  },
  gainAccess: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  interestButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "24px",
  },
  whatsAppButton: {
    background: "rgba(253, 253, 253, 0.25)",
    border: "1px solid rgba(253, 253, 253, 1)",
    color: "white",
    "&:hover": {
      background: "white",
      border: "1px solid rgba(253, 253, 253, 1)",
      color: "black",
    },
  },
}));

export default useStyles;
