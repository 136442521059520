import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Section } from "@atoms/Section";
import DynamicImage from "@ds/components/DynamicImage";
import Text from "@ds/components/Text";
import { Testimony as TestimonyTypes, Testimonials } from "@atoms/Testimonials";
import useStyles from "../styles";
import { useTranslation } from "next-i18next";
import ICONS from "@config/icons";

type TestimonialSectionProps = {
  testimony: TestimonyTypes;
};

const TestimonialSection: React.VFC<TestimonialSectionProps> = ({ testimony }) => {
  const classes = useStyles({ isVisible: true });
  const { t } = useTranslation("common");

  const corporateReview = [
    {
      title: t("testimoni.review.0.title"),
      image: ICONS.ICON_BUILDING,
      point: "250+",
      width: 18,
      height: 20,
    },
    {
      title: t("testimoni.review.1.title"),
      image: ICONS.ICON_GOV_BUILDING,
      point: "350+",
      width: 22,
      height: 21,
    },
    {
      title: t("testimoni.review.2.title"),
      image: ICONS.ICON_BUSINESS,
      point: "1.540+",
      width: 20,
      height: 18
    },
  ];

  return (
    <>
      <Section pt={10} pb={10} px="110px">
        <Container maxWidth="lg" disableGutters>
          <Grid container flexDirection="row-reverse" justifyContent="space-between">
            <Grid maxWidth="480px" item xs={6} sx={{ ".slick-dots": { bottom: "20px" } }}>
              <Testimonials data={testimony} minHeight="343px" />
            </Grid>
            <Grid item xs={6}>
              <Text className={classes.titleReguler} variant="h2" weight="700">
                {t("testimoni.title")}
              </Text>
              {corporateReview.map((item, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  marginBottom={item.point === "1.540+" ? 0 : "32px"}
                  key={index}
                >
                  <Box
                    width="48px"
                    height="48px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#E2E4E5"
                    borderRadius="50%"
                    marginRight="16px"
                  >
                    <Box width={item.width} height={item.height}>
                      <DynamicImage src={item.image} alt={item.title} />
                    </Box>
                  </Box>
                  <Box>
                    <Box fontSize="24px" fontWeight="bold" marginBottom="6px">
                      {item.point}
                    </Box>
                    <Box fontWeight="600">{item.title}</Box>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default TestimonialSection;
