import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

function RightArrow (props: IconProps) {
    return(
      <SvgIcon {...props} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.6665 12H19.6665" stroke="currentColor" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.6665 5L19.6665 12L12.6665 19" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </SvgIcon>
    )
}

export default RightArrow;