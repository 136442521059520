import React from "react";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import RightArrow from "@icons/RightArrow";
import color from "@definitions/mui/color";
import { Carousel } from "@atoms/Carousel";
import { Section } from "@atoms/Section";
import { ProductCard } from "@atoms/ProductCard";
import Button from "@ds/components/Button";
import Text from "@ds/components/Text";
import type { TPopularProduct } from "@containers/PopularProduct/type";

type ProductRecomendationSectionProps = {
  popularProduct: Array<TPopularProduct>;
  isDesktop: boolean;
};

const ProductRecomendationSection: React.VFC<ProductRecomendationSectionProps> = ({
  popularProduct,
  isDesktop,
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Section
        title={t("popular.title")}
        id="product-recommend"
        maxWidth="full-width"
        sx={{ ".slick-list": { paddingLeft: "0 !important" } }}
        px="110px"
      >
        <Container maxWidth="lg" disableGutters>
          <Carousel settings={{ slidesToShow: isDesktop ? 4 : 1 }} centerMode={true}>
            {popularProduct?.map((item: TPopularProduct) => {
              return (
                <Box px={1} key={item.id}>
                  <ProductCard
                    title={
                      <Text variant="h2" sx={{ fontSize: 18, lineHeight: "24px" }}>
                        {item.productName}
                      </Text>
                    }
                    image={
                      item.productYoutubeId
                        ? `https://img.youtube.com/vi/${item.productYoutubeId}/maxresdefault.jpg`
                        : item.productImage?.mediaPath
                    }
                    description={
                      <Box mb={1}>
                        <Text
                          noWrap
                          variant="body2"
                          color="blue-secondary"
                          sx={{ fontSize: "14px" }}
                        >
                          {item.parentProduct}
                        </Text>
                      </Box>
                    }
                    linkTo={item.productPath}
                    htmlDescription={item.story}
                    minHeight={348}
                    imgQuality={75}
                  >
                    <Box position="absolute" right={14} bottom={8}>
                      <RightArrow style={{ color: color.primary.main }} />
                    </Box>
                  </ProductCard>
                </Box>
              );
            })}
          </Carousel>
          <Box textAlign="center" marginTop="40px">
            <NextLink href="/produk-rekomendasi" passHref>
              <a>
                <Button size="large" variant="secondary">
                  {t("popular.button")}
                </Button>
              </a>
            </NextLink>
          </Box>
        </Container>
      </Section>
    </>
  );
};

export default ProductRecomendationSection;
