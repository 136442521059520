import { useRouter } from "next/router";
import ROUTES from "@config/routes";
import Button from "@ds/components/Button";
import Text from "@ds/components/Text";
import IconChatHelp from "@icons/IconChatHelp";
import { useTranslation } from "next-i18next";
import useResponsive from "@helpers/useResponsive";
import useStyles from "./styles";

const ButtonHelp: React.FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation("common");
  const smClient = useResponsive("sm");
  const mdClient = useResponsive("lg");

  return (
    <Button
      className={classes.btnhelp}
      size="large"
      onClick={() => router.push(ROUTES.HELP())}
      sx={{
        right: smClient ? 25 : mdClient ? "16px" : `calc((100vw - 1200px) / 2)`,
      }}
    >
      <IconChatHelp css={{ marginRight: smClient ? 0 : 10 }} />
      {!smClient && <Text color="white">{t("helper")}</Text>}
    </Button>
  );
};

export default ButtonHelp;
