import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Carousel } from "@atoms/Carousel";
import { Section } from "@atoms/Section";
import Button from "@ds/components/Button";
import Text from "@ds/components/Text";
import type { IBannerProps } from "../../../interface";
import useStyles from "../styles";
import DynamicImage from "@ds/components/DynamicImage";

type BigHeroSectionProps = {
  banner?: IBannerProps[];
  isDesktop?: boolean;
};

const BigHeroSection: React.VFC<BigHeroSectionProps> = ({ banner, isDesktop }) => {
  const classes = useStyles({ isVisible: true });
  const router = useRouter();
  const [getBannerWidth, setBannerWidth] = React.useState(1200);

  const updateBannerWidth = () => {
    const elementWidth = window.document.querySelector("#root-banner");
    if (elementWidth) {
      const resizeObserver = new ResizeObserver(() => {
        setBannerWidth(elementWidth.clientWidth);
      });
      resizeObserver.observe(elementWidth);
    }
  };

  React.useEffect(() => {
    const defaultWidth = window.document.getElementById("root-banner");
    if (defaultWidth) {
      setBannerWidth(defaultWidth.getBoundingClientRect().width);
    }
  }, [getBannerWidth]);

  React.useEffect(() => {
    window.addEventListener("orientationchange", updateBannerWidth);
    return () => {
      window.removeEventListener("orientationchange", updateBannerWidth);
    };
  }, []);

  const _renderFullBanner = (item: any, index: number) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        paddingX={isDesktop ? "120px" : "20px"}
        key={index}
        height="100%"
        sx={{
          backgroundImage: `linear-gradient(
            90deg,
            rgba(255, 255, 255, 100) 30%,
            rgba(255, 255, 255, 75) 50%,
            rgba(255, 255, 255, 0) 80%),
          url(${item.imageFull?.mediaPath + "?tr=w-1200"})`,
          backgroundSize: "contain",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth="lg" disableGutters sx={{ mb: 4 }} id="root-banner">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item xs={6}>
              {item.localizations
                .filter((val: any) => val.language === router.locale)
                .map((item: any) => (
                  <>
                    <Text
                      color="primary-main"
                      className={classes.subtitle}
                      variant="h2"
                      weight="700"
                    >
                      {item.titleHead}
                    </Text>
                    <Text className={classes.title} variant="h1" weight="700">
                      {item.title}
                    </Text>
                    <Text
                      component="div"
                      color="general-mid"
                      className={classes.text}
                      variant="body1"
                    >
                      <Box dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Text>
                    <Box display="flex" gap={2}>
                      {item.mainButton && (
                        <Link href={item.mainButton.link} passHref>
                          <Button tabIndex={-1}>{item.mainButton.label}</Button>
                        </Link>
                      )}
                      {item.optionalButton.label !== "" && (
                        <Link href={item.optionalButton.link} passHref>
                          <Button tabIndex={-1} variant="secondary">
                            {item.optionalButton.label}
                          </Button>
                        </Link>
                      )}
                    </Box>
                  </>
                ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const _renderSquareBanner = (item: any, index: number) => {
    return (
      <Box
        height="100%"
        paddingX={isDesktop ? "120px" : "20px"}
        display="flex"
        alignItems="center"
        key={index}
      >
        <Container maxWidth="lg" disableGutters sx={{ mb: 4 }} id="root-banner">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item xs={7}>
              {item.localizations
                .filter((val: any) => val.language === router.locale)
                .map((item: any) => (
                  <>
                    <Text
                      color="primary-main"
                      className={classes.subtitle}
                      variant="h2"
                      weight="700"
                    >
                      {item.titleHead}
                    </Text>
                    <Text className={classes.title} variant="h1" weight="700">
                      {item.title}
                    </Text>
                    <Text
                      component="div"
                      color="general-mid"
                      className={classes.text}
                      variant="body1"
                    >
                      <Box dangerouslySetInnerHTML={{ __html: item.description }} />
                    </Text>
                    <Box display="flex" gap={2}>
                      {item.mainButton && (
                        <Link href={item.mainButton.link} passHref>
                          <Button tabIndex={-1}>{item.mainButton.label}</Button>
                        </Link>
                      )}
                      {item.optionalButton.label !== "" && (
                        <Link href={item.optionalButton.link} passHref>
                          <Button tabIndex={-1} variant="secondary">
                            {item.optionalButton.label}
                          </Button>
                        </Link>
                      )}
                    </Box>
                  </>
                ))}
            </Grid>
            <Grid item xs={5}>
              <DynamicImage
                src={item.imageSquare?.mediaPath + "?tr=w-520"}
                alt={item.imageSquare?.mediaName}
                width={520}
                height={520}
                rounded
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  return (
    <>
      <Section
        maxWidth="full-width"
        pt={0}
        pb={6}
        position="relative"
        sx={{
          ".slick-slide": {
            height: "36rem",
          },
          ".slick-slide > div": {
            height: "100%",
          },
          ".slick-dots": {
            bottom: 25,
            // left: `calc((100vw - 1200px) / 2)`,
            left: `calc((100vw - ${getBannerWidth}px) / 2)`,
            width: "auto",
          },
          ".slick-dots li > button": {
            height: "auto",
          },
          ".slick-dots li > button:before": {
            content: "'-'",
          },
        }}
      >
        <Carousel
          arrowPosition="3vw"
          settings={{
            dots: true,
          }}
        >
          {banner?.map((item: any, index: number) => (
            <>
              {item?.isFullImage
                ? _renderFullBanner(item, index)
                : _renderSquareBanner(item, index)}
            </>
          ))}
        </Carousel>
      </Section>
    </>
  );
};

export default BigHeroSection;
