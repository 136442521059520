import { makeStyles } from "@mui/styles";

const useStyles = () =>{
  return makeStyles(() => ({
    btnhelp: {
      "&:hover": {
        opacity: 0.9,
      },
      position: "fixed",
      bottom: 25,
      background: "linear-gradient(89.45deg, #424349 31.76%, #5B5D66 96.06%)",
      transition: "ease-in-out 0.2s",
      zIndex: 3,
    },
  }))();
};

export default useStyles;
