import React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import FABScrollDown from "@fragments/FABScrollDown";
import Footer from "@layouts/Footer";
import ButtonHelp from "@atoms/ButtonHelp";
import type { ILandingPageProps } from "../../interface";
import BigHeroSection from "./partials/BigHeroSection";
import ProductMenuSection from "./partials/ProductSection";
import ProductRecomendationSection from "./partials/ProductRecomendationSection";
import SuperioritySection from "./partials/SuperioritySection";
import TestimonialSection from "./partials/TestimonialSection";
import BusinessSolutionSection from "./partials/BusinessSolutionSection";
import ArticleSection from "./partials/ArticleSection";
import useStyles from "./styles";

const Desktop: React.FC<ILandingPageProps> = ({
  popularProduct,
  banner,
  testimony,
  backgroundLead,
  dataNavbar,
  articleCustomer,
}) => {
  const classes = useStyles({ isVisible: true });
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const onClickToForm = () => {
    return document.querySelector("#product-customer")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const InterSectionComponent: React.ReactNode[] = [
    <ProductMenuSection />,
    <ProductRecomendationSection popularProduct={popularProduct} isDesktop={isDesktop} />,
    <SuperioritySection />,
    <TestimonialSection testimony={testimony} />,
    <ArticleSection articleCustomer={articleCustomer} isDesktop={isDesktop} />,
    <BusinessSolutionSection backgroundLead={backgroundLead} />,
  ].map((el, index: number) => {
    return <React.Fragment key={index}>{el}</React.Fragment>;
  });
  return (
    <>
      <Box className={classes.base}>
        <FABScrollDown id="fab-scroll-down" onClick={onClickToForm} />
        <BigHeroSection banner={banner} isDesktop={isDesktop} />
        {InterSectionComponent}
      </Box>
      <Footer bgColor="grey-mid" dataNavbar={dataNavbar} />
      <ButtonHelp />
    </>
  );
};

export default Desktop;
